.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  font-size: 0.75rem; }

.hr-text:before {
  content: '';
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(24, 28, 33, 0.6)), to(transparent));
  background: linear-gradient(to right, transparent, rgba(24, 28, 33, 0.6), transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px; }

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 .5em;
  line-height: 1.5em;
  background-color: #fff; }

#card-body {
  overflow-y: auto;
  height: 100vh; }

div.media-body {
  font-size: 0.85rem !important;
  line-height: 1.25rem !important; }

table.chit-table th:last-child {
  text-align: right; }

table.chit-table td:last-child {
  text-align: right; }

table.chit-table.edit th:last-child {
  text-align: right; }

table.chit-table.edit td:last-child {
  text-align: right; }

table.chit-table.edit td:nth-child(1) {
  width: 60%; }

table.chit-table.edit td:nth-child(2) {
  width: 30%; }

table.chit-table.edit td:nth-child(3) {
  width: 10%; }

table.chit-table.edit th:nth-child(2) {
  text-align: right; }

table.chit-table.edit td:nth-child(2) {
  text-align: right; }

.bg-lighter td {
  font-weight: bold !important; }

tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold; }

.form-label.hide {
  display: none !important; }

.text-twitter {
  color: #1DA1F2 !important; }

a.text-twitter:hover, a.text-twitter:focus {
  color: #0c85d0 !important; }

.bg-twitter {
  background-color: #1DA1F2 !important; }

a.bg-twitter:hover, a.bg-twitter:focus {
  background-color: #1c99e6 !important; }

.bg-twitter-dark {
  background-color: #1a91da !important; }

a.bg-twitter-dark:hover, a.bg-twitter-dark:focus {
  background-color: #198acf !important; }

.bg-twitter-darker {
  background-color: #1989ce !important; }

a.bg-twitter-darker:hover, a.bg-twitter-darker:focus {
  background-color: #1882c4 !important; }

.btn-twitter {
  border-color: transparent;
  background: #1DA1F2;
  color: #fff; }
  .btn-twitter:hover {
    border-color: transparent;
    background: #1c99e6;
    color: #fff; }
  .btn-twitter:focus, .btn-twitter.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.4);
            box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.4); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    border-color: transparent !important;
    background: #1DA1F2 !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #fff !important; }

.btn-twitter .badge {
  background: #fff;
  color: #1DA1F2; }

.btn-twitter:active,
.btn-twitter.active,
.show > .btn-twitter.dropdown-toggle {
  border-color: transparent;
  background: #198cd3;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-twitter,
.input-group-prepend .btn-twitter,
.input-group-append .btn-twitter {
  border-right: 1px solid #198cd3;
  border-left: 1px solid #198cd3; }

.btn-outline-twitter {
  border-color: #1DA1F2;
  background: transparent;
  color: #1DA1F2; }
  .btn-outline-twitter:hover {
    border-color: transparent;
    background: #1DA1F2;
    color: #fff; }
    .btn-outline-twitter:hover .badge {
      background: #fff;
      color: #1DA1F2; }
  .btn-outline-twitter:focus, .btn-outline-twitter.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.4);
            box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.4); }
  .btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
    border-color: #1DA1F2 !important;
    background: transparent !important;
    color: #1DA1F2 !important; }

.btn-outline-twitter .badge {
  background: #1DA1F2;
  color: #fff; }

.btn-outline-twitter:active,
.btn-outline-twitter.active,
.show > .btn-outline-twitter.dropdown-toggle {
  border-color: transparent;
  background: #1c99e6;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-twitter:active .badge,
  .btn-outline-twitter.active .badge,
  .show > .btn-outline-twitter.dropdown-toggle .badge {
    background: #fff;
    color: #1DA1F2; }

.text-google {
  color: #D1523F !important; }

a.text-google:hover, a.text-google:focus {
  color: #b23c2b !important; }

.bg-google {
  background-color: #D1523F !important; }

a.bg-google:hover, a.bg-google:focus {
  background-color: #c74e3c !important; }

.bg-google-dark {
  background-color: #bc4a39 !important; }

a.bg-google-dark:hover, a.bg-google-dark:focus {
  background-color: #b34636 !important; }

.bg-google-darker {
  background-color: #b24636 !important; }

a.bg-google-darker:hover, a.bg-google-darker:focus {
  background-color: #a94333 !important; }

.btn-google {
  border-color: transparent;
  background: #D1523F;
  color: #fff; }
  .btn-google:hover {
    border-color: transparent;
    background: #c74e3c;
    color: #fff; }
  .btn-google:focus, .btn-google.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(209, 82, 63, 0.4);
            box-shadow: 0 0 0 2px rgba(209, 82, 63, 0.4); }
  .btn-google.disabled, .btn-google:disabled {
    border-color: transparent !important;
    background: #D1523F !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #fff !important; }

.btn-google .badge {
  background: #fff;
  color: #D1523F; }

.btn-google:active,
.btn-google.active,
.show > .btn-google.dropdown-toggle {
  border-color: transparent;
  background: #b64737;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-google,
.input-group-prepend .btn-google,
.input-group-append .btn-google {
  border-right: 1px solid #b64737;
  border-left: 1px solid #b64737; }

.btn-outline-google {
  border-color: #D1523F;
  background: transparent;
  color: #D1523F; }
  .btn-outline-google:hover {
    border-color: transparent;
    background: #D1523F;
    color: #fff; }
    .btn-outline-google:hover .badge {
      background: #fff;
      color: #D1523F; }
  .btn-outline-google:focus, .btn-outline-google.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(209, 82, 63, 0.4);
            box-shadow: 0 0 0 2px rgba(209, 82, 63, 0.4); }
  .btn-outline-google.disabled, .btn-outline-google:disabled {
    border-color: #D1523F !important;
    background: transparent !important;
    color: #D1523F !important; }

.btn-outline-google .badge {
  background: #D1523F;
  color: #fff; }

.btn-outline-google:active,
.btn-outline-google.active,
.show > .btn-outline-google.dropdown-toggle {
  border-color: transparent;
  background: #c74e3c;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-google:active .badge,
  .btn-outline-google.active .badge,
  .show > .btn-outline-google.dropdown-toggle .badge {
    background: #fff;
    color: #D1523F; }

.text-facebook {
  color: #3B5998 !important; }

a.text-facebook:hover, a.text-facebook:focus {
  color: #2d4373 !important; }

.bg-facebook {
  background-color: #3B5998 !important; }

a.bg-facebook:hover, a.bg-facebook:focus {
  background-color: #385590 !important; }

.bg-facebook-dark {
  background-color: #355089 !important; }

a.bg-facebook-dark:hover, a.bg-facebook-dark:focus {
  background-color: #324c82 !important; }

.bg-facebook-darker {
  background-color: #324c81 !important; }

a.bg-facebook-darker:hover, a.bg-facebook-darker:focus {
  background-color: #30487b !important; }

.btn-facebook {
  border-color: transparent;
  background: #3B5998;
  color: #fff; }
  .btn-facebook:hover {
    border-color: transparent;
    background: #385590;
    color: #fff; }
  .btn-facebook:focus, .btn-facebook.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.4);
            box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.4); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    border-color: transparent !important;
    background: #3B5998 !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #fff !important; }

.btn-facebook .badge {
  background: #fff;
  color: #3B5998; }

.btn-facebook:active,
.btn-facebook.active,
.show > .btn-facebook.dropdown-toggle {
  border-color: transparent;
  background: #334d84;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-facebook,
.input-group-prepend .btn-facebook,
.input-group-append .btn-facebook {
  border-right: 1px solid #334d84;
  border-left: 1px solid #334d84; }

.btn-outline-facebook {
  border-color: #3B5998;
  background: transparent;
  color: #3B5998; }
  .btn-outline-facebook:hover {
    border-color: transparent;
    background: #3B5998;
    color: #fff; }
    .btn-outline-facebook:hover .badge {
      background: #fff;
      color: #3B5998; }
  .btn-outline-facebook:focus, .btn-outline-facebook.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.4);
            box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.4); }
  .btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
    border-color: #3B5998 !important;
    background: transparent !important;
    color: #3B5998 !important; }

.btn-outline-facebook .badge {
  background: #3B5998;
  color: #fff; }

.btn-outline-facebook:active,
.btn-outline-facebook.active,
.show > .btn-outline-facebook.dropdown-toggle {
  border-color: transparent;
  background: #385590;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-facebook:active .badge,
  .btn-outline-facebook.active .badge,
  .show > .btn-outline-facebook.dropdown-toggle .badge {
    background: #fff;
    color: #3B5998; }

.text-pinterest {
  color: #bd121c !important; }

a.text-pinterest:hover, a.text-pinterest:focus {
  color: #8e0e15 !important; }

.bg-pinterest {
  background-color: #bd121c !important; }

a.bg-pinterest:hover, a.bg-pinterest:focus {
  background-color: #b4111b !important; }

.bg-pinterest-dark {
  background-color: #aa1019 !important; }

a.bg-pinterest-dark:hover, a.bg-pinterest-dark:focus {
  background-color: #a20f18 !important; }

.bg-pinterest-darker {
  background-color: #a10f18 !important; }

a.bg-pinterest-darker:hover, a.bg-pinterest-darker:focus {
  background-color: #990e17 !important; }

.btn-pinterest {
  border-color: transparent;
  background: #bd121c;
  color: #fff; }
  .btn-pinterest:hover {
    border-color: transparent;
    background: #b4111b;
    color: #fff; }
  .btn-pinterest:focus, .btn-pinterest.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(189, 18, 28, 0.4);
            box-shadow: 0 0 0 2px rgba(189, 18, 28, 0.4); }
  .btn-pinterest.disabled, .btn-pinterest:disabled {
    border-color: transparent !important;
    background: #bd121c !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #fff !important; }

.btn-pinterest .badge {
  background: #fff;
  color: #bd121c; }

.btn-pinterest:active,
.btn-pinterest.active,
.show > .btn-pinterest.dropdown-toggle {
  border-color: transparent;
  background: #a41018;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-pinterest,
.input-group-prepend .btn-pinterest,
.input-group-append .btn-pinterest {
  border-right: 1px solid #a41018;
  border-left: 1px solid #a41018; }

.btn-outline-pinterest {
  border-color: #bd121c;
  background: transparent;
  color: #bd121c; }
  .btn-outline-pinterest:hover {
    border-color: transparent;
    background: #bd121c;
    color: #fff; }
    .btn-outline-pinterest:hover .badge {
      background: #fff;
      color: #bd121c; }
  .btn-outline-pinterest:focus, .btn-outline-pinterest.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(189, 18, 28, 0.4);
            box-shadow: 0 0 0 2px rgba(189, 18, 28, 0.4); }
  .btn-outline-pinterest.disabled, .btn-outline-pinterest:disabled {
    border-color: #bd121c !important;
    background: transparent !important;
    color: #bd121c !important; }

.btn-outline-pinterest .badge {
  background: #bd121c;
  color: #fff; }

.btn-outline-pinterest:active,
.btn-outline-pinterest.active,
.show > .btn-outline-pinterest.dropdown-toggle {
  border-color: transparent;
  background: #b4111b;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-pinterest:active .badge,
  .btn-outline-pinterest.active .badge,
  .show > .btn-outline-pinterest.dropdown-toggle .badge {
    background: #fff;
    color: #bd121c; }

.text-instagram {
  color: #000 !important; }

a.text-instagram:hover, a.text-instagram:focus {
  color: black !important; }

.bg-instagram {
  background-color: #000 !important; }

a.bg-instagram:hover, a.bg-instagram:focus {
  background-color: black !important; }

.bg-instagram-dark {
  background-color: black !important; }

a.bg-instagram-dark:hover, a.bg-instagram-dark:focus {
  background-color: black !important; }

.bg-instagram-darker {
  background-color: black !important; }

a.bg-instagram-darker:hover, a.bg-instagram-darker:focus {
  background-color: black !important; }

.btn-instagram {
  border-color: transparent;
  background: #000;
  color: #fff; }
  .btn-instagram:hover {
    border-color: transparent;
    background: black;
    color: #fff; }
  .btn-instagram:focus, .btn-instagram.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4); }
  .btn-instagram.disabled, .btn-instagram:disabled {
    border-color: transparent !important;
    background: #000 !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #fff !important; }

.btn-instagram .badge {
  background: #fff;
  color: #000; }

.btn-instagram:active,
.btn-instagram.active,
.show > .btn-instagram.dropdown-toggle {
  border-color: transparent;
  background: black;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-instagram,
.input-group-prepend .btn-instagram,
.input-group-append .btn-instagram {
  border-right: 1px solid black;
  border-left: 1px solid black; }

.btn-outline-instagram {
  border-color: #000;
  background: transparent;
  color: #000; }
  .btn-outline-instagram:hover {
    border-color: transparent;
    background: #000;
    color: #fff; }
    .btn-outline-instagram:hover .badge {
      background: #fff;
      color: #000; }
  .btn-outline-instagram:focus, .btn-outline-instagram.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4); }
  .btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
    border-color: #000 !important;
    background: transparent !important;
    color: #000 !important; }

.btn-outline-instagram .badge {
  background: #000;
  color: #fff; }

.btn-outline-instagram:active,
.btn-outline-instagram.active,
.show > .btn-outline-instagram.dropdown-toggle {
  border-color: transparent;
  background: black;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-instagram:active .badge,
  .btn-outline-instagram.active .badge,
  .show > .btn-outline-instagram.dropdown-toggle .badge {
    background: #fff;
    color: #000; }

.text-windows {
  color: #26AAE1 !important; }

a.text-windows:hover, a.text-windows:focus {
  color: #1a8bba !important; }

.bg-windows {
  background-color: #26AAE1 !important; }

a.bg-windows:hover, a.bg-windows:focus {
  background-color: #24a2d6 !important; }

.bg-windows-dark {
  background-color: #2299cb !important; }

a.bg-windows-dark:hover, a.bg-windows-dark:focus {
  background-color: #2091c1 !important; }

.bg-windows-darker {
  background-color: #2091bf !important; }

a.bg-windows-darker:hover, a.bg-windows-darker:focus {
  background-color: #1e8ab5 !important; }

.btn-windows {
  border-color: transparent;
  background: #26AAE1;
  color: #fff; }
  .btn-windows:hover {
    border-color: transparent;
    background: #24a2d6;
    color: #fff; }
  .btn-windows:focus, .btn-windows.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(38, 170, 225, 0.4);
            box-shadow: 0 0 0 2px rgba(38, 170, 225, 0.4); }
  .btn-windows.disabled, .btn-windows:disabled {
    border-color: transparent !important;
    background: #26AAE1 !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #fff !important; }

.btn-windows .badge {
  background: #fff;
  color: #26AAE1; }

.btn-windows:active,
.btn-windows.active,
.show > .btn-windows.dropdown-toggle {
  border-color: transparent;
  background: #2194c4;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-windows,
.input-group-prepend .btn-windows,
.input-group-append .btn-windows {
  border-right: 1px solid #2194c4;
  border-left: 1px solid #2194c4; }

.btn-outline-windows {
  border-color: #26AAE1;
  background: transparent;
  color: #26AAE1; }
  .btn-outline-windows:hover {
    border-color: transparent;
    background: #26AAE1;
    color: #fff; }
    .btn-outline-windows:hover .badge {
      background: #fff;
      color: #26AAE1; }
  .btn-outline-windows:focus, .btn-outline-windows.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(38, 170, 225, 0.4);
            box-shadow: 0 0 0 2px rgba(38, 170, 225, 0.4); }
  .btn-outline-windows.disabled, .btn-outline-windows:disabled {
    border-color: #26AAE1 !important;
    background: transparent !important;
    color: #26AAE1 !important; }

.btn-outline-windows .badge {
  background: #26AAE1;
  color: #fff; }

.btn-outline-windows:active,
.btn-outline-windows.active,
.show > .btn-outline-windows.dropdown-toggle {
  border-color: transparent;
  background: #24a2d6;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-windows:active .badge,
  .btn-outline-windows.active .badge,
  .show > .btn-outline-windows.dropdown-toggle .badge {
    background: #fff;
    color: #26AAE1; }

.text-macos {
  color: #343434 !important; }

a.text-macos:hover, a.text-macos:focus {
  color: #1b1b1b !important; }

.bg-macos {
  background-color: #343434 !important; }

a.bg-macos:hover, a.bg-macos:focus {
  background-color: #313131 !important; }

.bg-macos-dark {
  background-color: #2f2f2f !important; }

a.bg-macos-dark:hover, a.bg-macos-dark:focus {
  background-color: #2d2d2d !important; }

.bg-macos-darker {
  background-color: #2c2c2c !important; }

a.bg-macos-darker:hover, a.bg-macos-darker:focus {
  background-color: #2a2a2a !important; }

.btn-macos {
  border-color: transparent;
  background: #343434;
  color: #fff; }
  .btn-macos:hover {
    border-color: transparent;
    background: #313131;
    color: #fff; }
  .btn-macos:focus, .btn-macos.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(52, 52, 52, 0.4);
            box-shadow: 0 0 0 2px rgba(52, 52, 52, 0.4); }
  .btn-macos.disabled, .btn-macos:disabled {
    border-color: transparent !important;
    background: #343434 !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #fff !important; }

.btn-macos .badge {
  background: #fff;
  color: #343434; }

.btn-macos:active,
.btn-macos.active,
.show > .btn-macos.dropdown-toggle {
  border-color: transparent;
  background: #2d2d2d;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-macos,
.input-group-prepend .btn-macos,
.input-group-append .btn-macos {
  border-right: 1px solid #2d2d2d;
  border-left: 1px solid #2d2d2d; }

.btn-outline-macos {
  border-color: #343434;
  background: transparent;
  color: #343434; }
  .btn-outline-macos:hover {
    border-color: transparent;
    background: #343434;
    color: #fff; }
    .btn-outline-macos:hover .badge {
      background: #fff;
      color: #343434; }
  .btn-outline-macos:focus, .btn-outline-macos.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(52, 52, 52, 0.4);
            box-shadow: 0 0 0 2px rgba(52, 52, 52, 0.4); }
  .btn-outline-macos.disabled, .btn-outline-macos:disabled {
    border-color: #343434 !important;
    background: transparent !important;
    color: #343434 !important; }

.btn-outline-macos .badge {
  background: #343434;
  color: #fff; }

.btn-outline-macos:active,
.btn-outline-macos.active,
.show > .btn-outline-macos.dropdown-toggle {
  border-color: transparent;
  background: #313131;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-macos:active .badge,
  .btn-outline-macos.active .badge,
  .show > .btn-outline-macos.dropdown-toggle .badge {
    background: #fff;
    color: #343434; }

.text-linux {
  color: #800080 !important; }

a.text-linux:hover, a.text-linux:focus {
  color: #4d004d !important; }

.bg-linux {
  background-color: #800080 !important; }

a.bg-linux:hover, a.bg-linux:focus {
  background-color: #7a007a !important; }

.bg-linux-dark {
  background-color: #730073 !important; }

a.bg-linux-dark:hover, a.bg-linux-dark:focus {
  background-color: #6d006d !important; }

.bg-linux-darker {
  background-color: #6d006d !important; }

a.bg-linux-darker:hover, a.bg-linux-darker:focus {
  background-color: #680068 !important; }

.btn-linux {
  border-color: transparent;
  background: #800080;
  color: #fff; }
  .btn-linux:hover {
    border-color: transparent;
    background: #7a007a;
    color: #fff; }
  .btn-linux:focus, .btn-linux.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(128, 0, 128, 0.4);
            box-shadow: 0 0 0 2px rgba(128, 0, 128, 0.4); }
  .btn-linux.disabled, .btn-linux:disabled {
    border-color: transparent !important;
    background: #800080 !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #fff !important; }

.btn-linux .badge {
  background: #fff;
  color: #800080; }

.btn-linux:active,
.btn-linux.active,
.show > .btn-linux.dropdown-toggle {
  border-color: transparent;
  background: #6f006f;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-linux,
.input-group-prepend .btn-linux,
.input-group-append .btn-linux {
  border-right: 1px solid #6f006f;
  border-left: 1px solid #6f006f; }

.btn-outline-linux {
  border-color: #800080;
  background: transparent;
  color: #800080; }
  .btn-outline-linux:hover {
    border-color: transparent;
    background: #800080;
    color: #fff; }
    .btn-outline-linux:hover .badge {
      background: #fff;
      color: #800080; }
  .btn-outline-linux:focus, .btn-outline-linux.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(128, 0, 128, 0.4);
            box-shadow: 0 0 0 2px rgba(128, 0, 128, 0.4); }
  .btn-outline-linux.disabled, .btn-outline-linux:disabled {
    border-color: #800080 !important;
    background: transparent !important;
    color: #800080 !important; }

.btn-outline-linux .badge {
  background: #800080;
  color: #fff; }

.btn-outline-linux:active,
.btn-outline-linux.active,
.show > .btn-outline-linux.dropdown-toggle {
  border-color: transparent;
  background: #7a007a;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-linux:active .badge,
  .btn-outline-linux.active .badge,
  .show > .btn-outline-linux.dropdown-toggle .badge {
    background: #fff;
    color: #800080; }

.btn-white {
  border-color: transparent;
  background: #fff;
  color: #4E5155; }
  .btn-white:hover {
    border-color: transparent;
    background: #f2f2f2;
    color: #4E5155; }
  .btn-white:focus, .btn-white.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4); }
  .btn-white.disabled, .btn-white:disabled {
    border-color: transparent !important;
    background: #fff !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #4E5155 !important; }

.btn-white .badge {
  background: #4E5155;
  color: #fff; }

.btn-white:active,
.btn-white.active,
.show > .btn-white.dropdown-toggle {
  border-color: transparent;
  background: #dedede;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-white,
.input-group-prepend .btn-white,
.input-group-append .btn-white {
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede; }

.btn-outline-white {
  border-color: #fff;
  background: transparent;
  color: #fff; }
  .btn-outline-white:hover {
    border-color: transparent;
    background: #fff;
    color: #4E5155; }
    .btn-outline-white:hover .badge {
      background: #4E5155;
      color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    border-color: #fff !important;
    background: transparent !important;
    color: #fff !important; }

.btn-outline-white .badge {
  background: #fff;
  color: #fff; }

.btn-outline-white:active,
.btn-outline-white.active,
.show > .btn-outline-white.dropdown-toggle {
  border-color: transparent;
  background: #f2f2f2;
  color: #4E5155;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-white:active .badge,
  .btn-outline-white.active .badge,
  .show > .btn-outline-white.dropdown-toggle .badge {
    background: #4E5155;
    color: #fff; }

.custom-control.custom-control-black .custom-control-label::before,
.custom-control.custom-control-black .custom-control-input:active ~ .custom-control-label::before {
  background-color: #000; }

.custom-control.custom-control-black .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-black .custom-control-input:active ~ .custom-control-label::before {
  border-color: #000; }

.custom-control.custom-control-black .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4); }

.custom-control.custom-control-black.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-black.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #000;
  background-color: #000; }

.custom-control.custom-control-black.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.custom-control.custom-control-black.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23fff'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23fff'/%3E%3C/svg%3E"); }

.custom-control.custom-control-white .custom-control-label::before,
.custom-control.custom-control-white .custom-control-input:active ~ .custom-control-label::before {
  background-color: #fff; }

.custom-control.custom-control-white .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-white .custom-control-input:active ~ .custom-control-label::before {
  border-color: #fff; }

.custom-control.custom-control-white .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4); }

.custom-control.custom-control-white.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-white.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #fff;
  background-color: #fff; }

.custom-control.custom-control-white.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23666666;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.custom-control.custom-control-white.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23666666'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23666666'/%3E%3C/svg%3E"); }

.custom-control.custom-control-silver .custom-control-label::before,
.custom-control.custom-control-silver .custom-control-input:active ~ .custom-control-label::before {
  background-color: #eee; }

.custom-control.custom-control-silver .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-silver .custom-control-input:active ~ .custom-control-label::before {
  border-color: #eee; }

.custom-control.custom-control-silver .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(238, 238, 238, 0.4);
          box-shadow: 0 0 0 2px rgba(238, 238, 238, 0.4); }

.custom-control.custom-control-silver.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-silver.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #eee;
  background-color: #eee; }

.custom-control.custom-control-silver.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%235f5f5f;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.custom-control.custom-control-silver.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%235f5f5f'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%235f5f5f'/%3E%3C/svg%3E"); }

.custom-control.custom-control-gray .custom-control-label::before,
.custom-control.custom-control-gray .custom-control-input:active ~ .custom-control-label::before {
  background-color: #777; }

.custom-control.custom-control-gray .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-gray .custom-control-input:active ~ .custom-control-label::before {
  border-color: #777; }

.custom-control.custom-control-gray .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(119, 119, 119, 0.4);
          box-shadow: 0 0 0 2px rgba(119, 119, 119, 0.4); }

.custom-control.custom-control-gray.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-gray.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #777;
  background-color: #777; }

.custom-control.custom-control-gray.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.custom-control.custom-control-gray.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23fff'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23fff'/%3E%3C/svg%3E"); }

.custom-control.custom-control-gold .custom-control-label::before,
.custom-control.custom-control-gold .custom-control-input:active ~ .custom-control-label::before {
  background-color: #ffeb3b; }

.custom-control.custom-control-gold .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-gold .custom-control-input:active ~ .custom-control-label::before {
  border-color: #ffeb3b; }

.custom-control.custom-control-gold .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(255, 235, 59, 0.4);
          box-shadow: 0 0 0 2px rgba(255, 235, 59, 0.4); }

.custom-control.custom-control-gold.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-gold.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ffeb3b;
  background-color: #ffeb3b; }

.custom-control.custom-control-gold.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23665e18;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.custom-control.custom-control-gold.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23665e18'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23665e18'/%3E%3C/svg%3E"); }

.custom-control.custom-control-pink .custom-control-label::before,
.custom-control.custom-control-pink .custom-control-input:active ~ .custom-control-label::before {
  background-color: #e91e63; }

.custom-control.custom-control-pink .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-pink .custom-control-input:active ~ .custom-control-label::before {
  border-color: #e91e63; }

.custom-control.custom-control-pink .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(233, 30, 99, 0.4);
          box-shadow: 0 0 0 2px rgba(233, 30, 99, 0.4); }

.custom-control.custom-control-pink.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-pink.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #e91e63;
  background-color: #e91e63; }

.custom-control.custom-control-pink.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.custom-control.custom-control-pink.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23fff'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23fff'/%3E%3C/svg%3E"); }

.custom-control.custom-control-red .custom-control-label::before,
.custom-control.custom-control-red .custom-control-input:active ~ .custom-control-label::before {
  background-color: #f44336; }

.custom-control.custom-control-red .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-red .custom-control-input:active ~ .custom-control-label::before {
  border-color: #f44336; }

.custom-control.custom-control-red .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 67, 54, 0.4);
          box-shadow: 0 0 0 2px rgba(244, 67, 54, 0.4); }

.custom-control.custom-control-red.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-red.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #f44336;
  background-color: #f44336; }

.custom-control.custom-control-red.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.custom-control.custom-control-red.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23fff'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23fff'/%3E%3C/svg%3E"); }

.navbar.bg-secondary {
  background-color: #8897AA !important;
  color: #ebeef1; }
  .navbar.bg-secondary .navbar-brand {
    color: #fff; }
    .navbar.bg-secondary .navbar-brand:hover, .navbar.bg-secondary .navbar-brand:focus {
      color: #fff; }
  .navbar.bg-secondary .navbar-nav .nav-link {
    color: #ebeef1; }
    .navbar.bg-secondary .navbar-nav .nav-link:hover, .navbar.bg-secondary .navbar-nav .nav-link:focus {
      color: #fff; }
    .navbar.bg-secondary .navbar-nav .nav-link.disabled {
      color: #c3cbd5 !important; }
  .navbar.bg-secondary .navbar-nav .show > .nav-link,
  .navbar.bg-secondary .navbar-nav .active > .nav-link,
  .navbar.bg-secondary .navbar-nav .nav-link.show,
  .navbar.bg-secondary .navbar-nav .nav-link.active {
    color: #fff; }
  .navbar.bg-secondary .navbar-toggler {
    color: #ebeef1;
    border-color: rgba(255, 255, 255, 0.15); }
  .navbar.bg-secondary .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-secondary .navbar-text {
    color: #ebeef1; }
    .navbar.bg-secondary .navbar-text a {
      color: #fff; }
      .navbar.bg-secondary .navbar-text a:hover, .navbar.bg-secondary .navbar-text a:focus {
        color: #fff; }
  .navbar.bg-secondary hr {
    border-color: rgba(255, 255, 255, 0.15); }

.navbar.bg-success {
  background-color: #02BC77 !important;
  color: #cbf1e3; }
  .navbar.bg-success .navbar-brand {
    color: #fff; }
    .navbar.bg-success .navbar-brand:hover, .navbar.bg-success .navbar-brand:focus {
      color: #fff; }
  .navbar.bg-success .navbar-nav .nav-link {
    color: #cbf1e3; }
    .navbar.bg-success .navbar-nav .nav-link:hover, .navbar.bg-success .navbar-nav .nav-link:focus {
      color: #fff; }
    .navbar.bg-success .navbar-nav .nav-link.disabled {
      color: #7bdcb8 !important; }
  .navbar.bg-success .navbar-nav .show > .nav-link,
  .navbar.bg-success .navbar-nav .active > .nav-link,
  .navbar.bg-success .navbar-nav .nav-link.show,
  .navbar.bg-success .navbar-nav .nav-link.active {
    color: #fff; }
  .navbar.bg-success .navbar-toggler {
    color: #cbf1e3;
    border-color: rgba(255, 255, 255, 0.15); }
  .navbar.bg-success .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-success .navbar-text {
    color: #cbf1e3; }
    .navbar.bg-success .navbar-text a {
      color: #fff; }
      .navbar.bg-success .navbar-text a:hover, .navbar.bg-success .navbar-text a:focus {
        color: #fff; }
  .navbar.bg-success hr {
    border-color: rgba(255, 255, 255, 0.15); }

.navbar.bg-info {
  background-color: #28c3d7 !important;
  color: #dcf5f8; }
  .navbar.bg-info .navbar-brand {
    color: #fff; }
    .navbar.bg-info .navbar-brand:hover, .navbar.bg-info .navbar-brand:focus {
      color: #fff; }
  .navbar.bg-info .navbar-nav .nav-link {
    color: #dcf5f8; }
    .navbar.bg-info .navbar-nav .nav-link:hover, .navbar.bg-info .navbar-nav .nav-link:focus {
      color: #fff; }
    .navbar.bg-info .navbar-nav .nav-link.disabled {
      color: #94e1eb !important; }
  .navbar.bg-info .navbar-nav .show > .nav-link,
  .navbar.bg-info .navbar-nav .active > .nav-link,
  .navbar.bg-info .navbar-nav .nav-link.show,
  .navbar.bg-info .navbar-nav .nav-link.active {
    color: #fff; }
  .navbar.bg-info .navbar-toggler {
    color: #dcf5f8;
    border-color: rgba(255, 255, 255, 0.15); }
  .navbar.bg-info .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-info .navbar-text {
    color: #dcf5f8; }
    .navbar.bg-info .navbar-text a {
      color: #fff; }
      .navbar.bg-info .navbar-text a:hover, .navbar.bg-info .navbar-text a:focus {
        color: #fff; }
  .navbar.bg-info hr {
    border-color: rgba(255, 255, 255, 0.15); }

.navbar.bg-warning {
  background-color: #FFD950 !important;
  color: #998230; }
  .navbar.bg-warning .navbar-brand {
    color: #665720; }
    .navbar.bg-warning .navbar-brand:hover, .navbar.bg-warning .navbar-brand:focus {
      color: #665720; }
  .navbar.bg-warning .navbar-nav .nav-link {
    color: #998230; }
    .navbar.bg-warning .navbar-nav .nav-link:hover, .navbar.bg-warning .navbar-nav .nav-link:focus {
      color: #665720; }
    .navbar.bg-warning .navbar-nav .nav-link.disabled {
      color: #c2a53d !important; }
  .navbar.bg-warning .navbar-nav .show > .nav-link,
  .navbar.bg-warning .navbar-nav .active > .nav-link,
  .navbar.bg-warning .navbar-nav .nav-link.show,
  .navbar.bg-warning .navbar-nav .nav-link.active {
    color: #665720; }
  .navbar.bg-warning .navbar-toggler {
    color: #998230;
    border-color: rgba(102, 87, 32, 0.0832855); }
  .navbar.bg-warning .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-warning .navbar-text {
    color: #998230; }
    .navbar.bg-warning .navbar-text a {
      color: #665720; }
      .navbar.bg-warning .navbar-text a:hover, .navbar.bg-warning .navbar-text a:focus {
        color: #665720; }
  .navbar.bg-warning hr {
    border-color: rgba(102, 87, 32, 0.0832855); }

.navbar.bg-danger {
  background-color: #d9534f !important;
  color: #f7dbda; }
  .navbar.bg-danger .navbar-brand {
    color: #fff; }
    .navbar.bg-danger .navbar-brand:hover, .navbar.bg-danger .navbar-brand:focus {
      color: #fff; }
  .navbar.bg-danger .navbar-nav .nav-link {
    color: #f7dbda; }
    .navbar.bg-danger .navbar-nav .nav-link:hover, .navbar.bg-danger .navbar-nav .nav-link:focus {
      color: #fff; }
    .navbar.bg-danger .navbar-nav .nav-link.disabled {
      color: #eba5a2 !important; }
  .navbar.bg-danger .navbar-nav .show > .nav-link,
  .navbar.bg-danger .navbar-nav .active > .nav-link,
  .navbar.bg-danger .navbar-nav .nav-link.show,
  .navbar.bg-danger .navbar-nav .nav-link.active {
    color: #fff; }
  .navbar.bg-danger .navbar-toggler {
    color: #f7dbda;
    border-color: rgba(255, 255, 255, 0.15); }
  .navbar.bg-danger .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-danger .navbar-text {
    color: #f7dbda; }
    .navbar.bg-danger .navbar-text a {
      color: #fff; }
      .navbar.bg-danger .navbar-text a:hover, .navbar.bg-danger .navbar-text a:focus {
        color: #fff; }
  .navbar.bg-danger hr {
    border-color: rgba(255, 255, 255, 0.15); }

.navbar.bg-dark {
  background-color: #2f3337 !important;
  color: #9d9fa1; }
  .navbar.bg-dark .navbar-brand {
    color: #fff; }
    .navbar.bg-dark .navbar-brand:hover, .navbar.bg-dark .navbar-brand:focus {
      color: #fff; }
  .navbar.bg-dark .navbar-nav .nav-link {
    color: #9d9fa1; }
    .navbar.bg-dark .navbar-nav .nav-link:hover, .navbar.bg-dark .navbar-nav .nav-link:focus {
      color: #fff; }
    .navbar.bg-dark .navbar-nav .nav-link.disabled {
      color: #717477 !important; }
  .navbar.bg-dark .navbar-nav .show > .nav-link,
  .navbar.bg-dark .navbar-nav .active > .nav-link,
  .navbar.bg-dark .navbar-nav .nav-link.show,
  .navbar.bg-dark .navbar-nav .nav-link.active {
    color: #fff; }
  .navbar.bg-dark .navbar-toggler {
    color: #9d9fa1;
    border-color: rgba(255, 255, 255, 0.06); }
  .navbar.bg-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-dark .navbar-text {
    color: #9d9fa1; }
    .navbar.bg-dark .navbar-text a {
      color: #fff; }
      .navbar.bg-dark .navbar-text a:hover, .navbar.bg-dark .navbar-text a:focus {
        color: #fff; }
  .navbar.bg-dark hr {
    border-color: rgba(255, 255, 255, 0.06); }

.navbar.bg-white {
  background-color: #fff !important;
  color: #a3a4a6; }
  .navbar.bg-white .navbar-brand {
    color: #4E5155; }
    .navbar.bg-white .navbar-brand:hover, .navbar.bg-white .navbar-brand:focus {
      color: #4E5155; }
  .navbar.bg-white .navbar-nav .nav-link {
    color: #a3a4a6; }
    .navbar.bg-white .navbar-nav .nav-link:hover, .navbar.bg-white .navbar-nav .nav-link:focus {
      color: #4E5155; }
    .navbar.bg-white .navbar-nav .nav-link.disabled {
      color: #c8c8ca !important; }
  .navbar.bg-white .navbar-nav .show > .nav-link,
  .navbar.bg-white .navbar-nav .active > .nav-link,
  .navbar.bg-white .navbar-nav .nav-link.show,
  .navbar.bg-white .navbar-nav .nav-link.active {
    color: #4E5155; }
  .navbar.bg-white .navbar-toggler {
    color: #a3a4a6;
    border-color: rgba(78, 81, 85, 0.075); }
  .navbar.bg-white .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-white .navbar-text {
    color: #a3a4a6; }
    .navbar.bg-white .navbar-text a {
      color: #4E5155; }
      .navbar.bg-white .navbar-text a:hover, .navbar.bg-white .navbar-text a:focus {
        color: #4E5155; }
  .navbar.bg-white hr {
    border-color: rgba(78, 81, 85, 0.075); }

.navbar.bg-light {
  background-color: #f1f1f2 !important;
  color: #a3a4a6; }
  .navbar.bg-light .navbar-brand {
    color: #4E5155; }
    .navbar.bg-light .navbar-brand:hover, .navbar.bg-light .navbar-brand:focus {
      color: #4E5155; }
  .navbar.bg-light .navbar-nav .nav-link {
    color: #a3a4a6; }
    .navbar.bg-light .navbar-nav .nav-link:hover, .navbar.bg-light .navbar-nav .nav-link:focus {
      color: #4E5155; }
    .navbar.bg-light .navbar-nav .nav-link.disabled {
      color: #c2c3c4 !important; }
  .navbar.bg-light .navbar-nav .show > .nav-link,
  .navbar.bg-light .navbar-nav .active > .nav-link,
  .navbar.bg-light .navbar-nav .nav-link.show,
  .navbar.bg-light .navbar-nav .nav-link.active {
    color: #4E5155; }
  .navbar.bg-light .navbar-toggler {
    color: #a3a4a6;
    border-color: rgba(78, 81, 85, 0.0777227); }
  .navbar.bg-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-light .navbar-text {
    color: #a3a4a6; }
    .navbar.bg-light .navbar-text a {
      color: #4E5155; }
      .navbar.bg-light .navbar-text a:hover, .navbar.bg-light .navbar-text a:focus {
        color: #4E5155; }
  .navbar.bg-light hr {
    border-color: rgba(78, 81, 85, 0.0777227); }

.navbar.bg-lighter {
  background-color: #f8f8f8 !important;
  color: #a3a4a6; }
  .navbar.bg-lighter .navbar-brand {
    color: #4E5155; }
    .navbar.bg-lighter .navbar-brand:hover, .navbar.bg-lighter .navbar-brand:focus {
      color: #4E5155; }
  .navbar.bg-lighter .navbar-nav .nav-link {
    color: #a3a4a6; }
    .navbar.bg-lighter .navbar-nav .nav-link:hover, .navbar.bg-lighter .navbar-nav .nav-link:focus {
      color: #4E5155; }
    .navbar.bg-lighter .navbar-nav .nav-link.disabled {
      color: #c5c6c7 !important; }
  .navbar.bg-lighter .navbar-nav .show > .nav-link,
  .navbar.bg-lighter .navbar-nav .active > .nav-link,
  .navbar.bg-lighter .navbar-nav .nav-link.show,
  .navbar.bg-lighter .navbar-nav .nav-link.active {
    color: #4E5155; }
  .navbar.bg-lighter .navbar-toggler {
    color: #a3a4a6;
    border-color: rgba(78, 81, 85, 0.0763725); }
  .navbar.bg-lighter .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-lighter .navbar-text {
    color: #a3a4a6; }
    .navbar.bg-lighter .navbar-text a {
      color: #4E5155; }
      .navbar.bg-lighter .navbar-text a:hover, .navbar.bg-lighter .navbar-text a:focus {
        color: #4E5155; }
  .navbar.bg-lighter hr {
    border-color: rgba(78, 81, 85, 0.0763725); }

.sidenav.bg-secondary {
  background-color: #8897AA !important;
  color: #ebeef1; }
  .sidenav.bg-secondary .sidenav-link,
  .sidenav.bg-secondary .sidenav-horizontal-prev,
  .sidenav.bg-secondary .sidenav-horizontal-next {
    color: #ebeef1; }
    .sidenav.bg-secondary .sidenav-link:hover, .sidenav.bg-secondary .sidenav-link:focus,
    .sidenav.bg-secondary .sidenav-horizontal-prev:hover,
    .sidenav.bg-secondary .sidenav-horizontal-prev:focus,
    .sidenav.bg-secondary .sidenav-horizontal-next:hover,
    .sidenav.bg-secondary .sidenav-horizontal-next:focus {
      color: #fff; }
    .sidenav.bg-secondary .sidenav-link.active,
    .sidenav.bg-secondary .sidenav-horizontal-prev.active,
    .sidenav.bg-secondary .sidenav-horizontal-next.active {
      color: #fff; }
  .sidenav.bg-secondary .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-secondary .sidenav-horizontal-prev.disabled,
  .sidenav.bg-secondary .sidenav-horizontal-next.disabled {
    color: #c3cbd5 !important; }
  .sidenav.bg-secondary .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-secondary .sidenav-item.active > .sidenav-link {
    color: #fff; }
  .sidenav.bg-secondary .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #818fa2; }
  .sidenav.bg-secondary.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #7b889a; }
  .sidenav.bg-secondary.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-secondary.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #818fa2; }
  .sidenav.bg-secondary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-secondary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #ebeef1; }
  .sidenav.bg-secondary .sidenav-text {
    color: #fff; }
  .sidenav.bg-secondary .sidenav-header {
    color: #d2d8df; }
  .sidenav.bg-secondary hr,
  .sidenav.bg-secondary .sidenav-divider,
  .sidenav.bg-secondary .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.15) !important; }
  .sidenav.bg-secondary .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-secondary .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.15); }
  .sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #94a0b0; }
  .sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff; }
  .sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #818fa2;
            box-shadow: 0 0 0 2px #818fa2; }
  .sidenav.bg-secondary .ps__thumb-y,
  .sidenav.bg-secondary .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, 0.633225) !important; }

.sidenav.bg-success {
  background-color: #02BC77 !important;
  color: #cbf1e3; }
  .sidenav.bg-success .sidenav-link,
  .sidenav.bg-success .sidenav-horizontal-prev,
  .sidenav.bg-success .sidenav-horizontal-next {
    color: #cbf1e3; }
    .sidenav.bg-success .sidenav-link:hover, .sidenav.bg-success .sidenav-link:focus,
    .sidenav.bg-success .sidenav-horizontal-prev:hover,
    .sidenav.bg-success .sidenav-horizontal-prev:focus,
    .sidenav.bg-success .sidenav-horizontal-next:hover,
    .sidenav.bg-success .sidenav-horizontal-next:focus {
      color: #fff; }
    .sidenav.bg-success .sidenav-link.active,
    .sidenav.bg-success .sidenav-horizontal-prev.active,
    .sidenav.bg-success .sidenav-horizontal-next.active {
      color: #fff; }
  .sidenav.bg-success .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-success .sidenav-horizontal-prev.disabled,
  .sidenav.bg-success .sidenav-horizontal-next.disabled {
    color: #7bdcb8 !important; }
  .sidenav.bg-success .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-success .sidenav-item.active > .sidenav-link {
    color: #fff; }
  .sidenav.bg-success .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #02b371; }
  .sidenav.bg-success.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #02aa6b; }
  .sidenav.bg-success.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-success.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #02b371; }
  .sidenav.bg-success .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-success .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #cbf1e3; }
  .sidenav.bg-success .sidenav-text {
    color: #fff; }
  .sidenav.bg-success .sidenav-header {
    color: #99e4c8; }
  .sidenav.bg-success hr,
  .sidenav.bg-success .sidenav-divider,
  .sidenav.bg-success .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.15) !important; }
  .sidenav.bg-success .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-success .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.15); }
  .sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #28be86; }
  .sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff; }
  .sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #02b371;
            box-shadow: 0 0 0 2px #02b371; }
  .sidenav.bg-success .ps__thumb-y,
  .sidenav.bg-success .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, 0.595325) !important; }

.sidenav.bg-info {
  background-color: #28c3d7 !important;
  color: #dcf5f8; }
  .sidenav.bg-info .sidenav-link,
  .sidenav.bg-info .sidenav-horizontal-prev,
  .sidenav.bg-info .sidenav-horizontal-next {
    color: #dcf5f8; }
    .sidenav.bg-info .sidenav-link:hover, .sidenav.bg-info .sidenav-link:focus,
    .sidenav.bg-info .sidenav-horizontal-prev:hover,
    .sidenav.bg-info .sidenav-horizontal-prev:focus,
    .sidenav.bg-info .sidenav-horizontal-next:hover,
    .sidenav.bg-info .sidenav-horizontal-next:focus {
      color: #fff; }
    .sidenav.bg-info .sidenav-link.active,
    .sidenav.bg-info .sidenav-horizontal-prev.active,
    .sidenav.bg-info .sidenav-horizontal-next.active {
      color: #fff; }
  .sidenav.bg-info .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-info .sidenav-horizontal-prev.disabled,
  .sidenav.bg-info .sidenav-horizontal-next.disabled {
    color: #94e1eb !important; }
  .sidenav.bg-info .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-info .sidenav-item.active > .sidenav-link {
    color: #fff; }
  .sidenav.bg-info .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #26b9cc; }
  .sidenav.bg-info.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #24b0c2; }
  .sidenav.bg-info.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-info.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #26b9cc; }
  .sidenav.bg-info .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-info .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #dcf5f8; }
  .sidenav.bg-info .sidenav-text {
    color: #fff; }
  .sidenav.bg-info .sidenav-header {
    color: #afe9f0; }
  .sidenav.bg-info hr,
  .sidenav.bg-info .sidenav-divider,
  .sidenav.bg-info .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.15) !important; }
  .sidenav.bg-info .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-info .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.15); }
  .sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #47c4d4; }
  .sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff; }
  .sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #26b9cc;
            box-shadow: 0 0 0 2px #26b9cc; }
  .sidenav.bg-info .ps__thumb-y,
  .sidenav.bg-info .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, 0.636761) !important; }

.sidenav.bg-warning {
  background-color: #FFD950 !important;
  color: #998230; }
  .sidenav.bg-warning .sidenav-link,
  .sidenav.bg-warning .sidenav-horizontal-prev,
  .sidenav.bg-warning .sidenav-horizontal-next {
    color: #998230; }
    .sidenav.bg-warning .sidenav-link:hover, .sidenav.bg-warning .sidenav-link:focus,
    .sidenav.bg-warning .sidenav-horizontal-prev:hover,
    .sidenav.bg-warning .sidenav-horizontal-prev:focus,
    .sidenav.bg-warning .sidenav-horizontal-next:hover,
    .sidenav.bg-warning .sidenav-horizontal-next:focus {
      color: #665720; }
    .sidenav.bg-warning .sidenav-link.active,
    .sidenav.bg-warning .sidenav-horizontal-prev.active,
    .sidenav.bg-warning .sidenav-horizontal-next.active {
      color: #665720; }
  .sidenav.bg-warning .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-warning .sidenav-horizontal-prev.disabled,
  .sidenav.bg-warning .sidenav-horizontal-next.disabled {
    color: #c2a53d !important; }
  .sidenav.bg-warning .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-warning .sidenav-item.active > .sidenav-link {
    color: #665720; }
  .sidenav.bg-warning .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #f9d44e; }
  .sidenav.bg-warning.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #f3cf4c; }
  .sidenav.bg-warning.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-warning.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #f9d44e; }
  .sidenav.bg-warning .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-warning .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #998230; }
  .sidenav.bg-warning .sidenav-text {
    color: #665720; }
  .sidenav.bg-warning .sidenav-header {
    color: #b39838; }
  .sidenav.bg-warning hr,
  .sidenav.bg-warning .sidenav-divider,
  .sidenav.bg-warning .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(102, 87, 32, 0.0832855) !important; }
  .sidenav.bg-warning .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-warning .sidenav-block::before {
    background-color: rgba(102, 87, 32, 0.0832855); }
  .sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #edca4a; }
  .sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #665720; }
  .sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #f9d44e;
            box-shadow: 0 0 0 2px #f9d44e; }
  .sidenav.bg-warning .ps__thumb-y,
  .sidenav.bg-warning .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(102, 87, 32, 0.266284) !important; }

.sidenav.bg-danger {
  background-color: #d9534f !important;
  color: #f7dbda; }
  .sidenav.bg-danger .sidenav-link,
  .sidenav.bg-danger .sidenav-horizontal-prev,
  .sidenav.bg-danger .sidenav-horizontal-next {
    color: #f7dbda; }
    .sidenav.bg-danger .sidenav-link:hover, .sidenav.bg-danger .sidenav-link:focus,
    .sidenav.bg-danger .sidenav-horizontal-prev:hover,
    .sidenav.bg-danger .sidenav-horizontal-prev:focus,
    .sidenav.bg-danger .sidenav-horizontal-next:hover,
    .sidenav.bg-danger .sidenav-horizontal-next:focus {
      color: #fff; }
    .sidenav.bg-danger .sidenav-link.active,
    .sidenav.bg-danger .sidenav-horizontal-prev.active,
    .sidenav.bg-danger .sidenav-horizontal-next.active {
      color: #fff; }
  .sidenav.bg-danger .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-danger .sidenav-horizontal-prev.disabled,
  .sidenav.bg-danger .sidenav-horizontal-next.disabled {
    color: #eba5a2 !important; }
  .sidenav.bg-danger .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-danger .sidenav-item.active > .sidenav-link {
    color: #fff; }
  .sidenav.bg-danger .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #ce4f4b; }
  .sidenav.bg-danger.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #c44b47; }
  .sidenav.bg-danger.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-danger.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #ce4f4b; }
  .sidenav.bg-danger .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-danger .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #f7dbda; }
  .sidenav.bg-danger .sidenav-text {
    color: #fff; }
  .sidenav.bg-danger .sidenav-header {
    color: #f0b9b7; }
  .sidenav.bg-danger hr,
  .sidenav.bg-danger .sidenav-divider,
  .sidenav.bg-danger .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.15) !important; }
  .sidenav.bg-danger .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-danger .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.15); }
  .sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #d56966; }
  .sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff; }
  .sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #ce4f4b;
            box-shadow: 0 0 0 2px #ce4f4b; }
  .sidenav.bg-danger .ps__thumb-y,
  .sidenav.bg-danger .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, 0.592329) !important; }

.sidenav.bg-dark {
  background-color: #2f3337 !important;
  color: #9d9fa1; }
  .sidenav.bg-dark .sidenav-link,
  .sidenav.bg-dark .sidenav-horizontal-prev,
  .sidenav.bg-dark .sidenav-horizontal-next {
    color: #9d9fa1; }
    .sidenav.bg-dark .sidenav-link:hover, .sidenav.bg-dark .sidenav-link:focus,
    .sidenav.bg-dark .sidenav-horizontal-prev:hover,
    .sidenav.bg-dark .sidenav-horizontal-prev:focus,
    .sidenav.bg-dark .sidenav-horizontal-next:hover,
    .sidenav.bg-dark .sidenav-horizontal-next:focus {
      color: #fff; }
    .sidenav.bg-dark .sidenav-link.active,
    .sidenav.bg-dark .sidenav-horizontal-prev.active,
    .sidenav.bg-dark .sidenav-horizontal-next.active {
      color: #fff; }
  .sidenav.bg-dark .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-dark .sidenav-horizontal-prev.disabled,
  .sidenav.bg-dark .sidenav-horizontal-next.disabled {
    color: #717477 !important; }
  .sidenav.bg-dark .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-dark .sidenav-item.active > .sidenav-link {
    color: #fff; }
  .sidenav.bg-dark .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #393d41; }
  .sidenav.bg-dark.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #43474b; }
  .sidenav.bg-dark.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-dark.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #393d41; }
  .sidenav.bg-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #9d9fa1; }
  .sidenav.bg-dark .sidenav-text {
    color: #fff; }
  .sidenav.bg-dark .sidenav-header {
    color: #828487; }
  .sidenav.bg-dark hr,
  .sidenav.bg-dark .sidenav-divider,
  .sidenav.bg-dark .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.06) !important; }
  .sidenav.bg-dark .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-dark .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.06); }
  .sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #45494c; }
  .sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff; }
  .sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #393d41;
            box-shadow: 0 0 0 2px #393d41; }
  .sidenav.bg-dark .ps__thumb-y,
  .sidenav.bg-dark .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, 0.478839) !important; }

.sidenav.bg-white {
  background-color: #fff !important;
  color: #a3a4a6; }
  .sidenav.bg-white .sidenav-link,
  .sidenav.bg-white .sidenav-horizontal-prev,
  .sidenav.bg-white .sidenav-horizontal-next {
    color: #a3a4a6; }
    .sidenav.bg-white .sidenav-link:hover, .sidenav.bg-white .sidenav-link:focus,
    .sidenav.bg-white .sidenav-horizontal-prev:hover,
    .sidenav.bg-white .sidenav-horizontal-prev:focus,
    .sidenav.bg-white .sidenav-horizontal-next:hover,
    .sidenav.bg-white .sidenav-horizontal-next:focus {
      color: #4E5155; }
    .sidenav.bg-white .sidenav-link.active,
    .sidenav.bg-white .sidenav-horizontal-prev.active,
    .sidenav.bg-white .sidenav-horizontal-next.active {
      color: #4E5155; }
  .sidenav.bg-white .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-white .sidenav-horizontal-prev.disabled,
  .sidenav.bg-white .sidenav-horizontal-next.disabled {
    color: #c8c8ca !important; }
  .sidenav.bg-white .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-white .sidenav-item.active > .sidenav-link {
    color: #4E5155; }
  .sidenav.bg-white .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #fff; }
  .sidenav.bg-white.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #f9f9f9; }
  .sidenav.bg-white.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-white.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #fff; }
  .sidenav.bg-white .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-white .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #a3a4a6; }
  .sidenav.bg-white .sidenav-text {
    color: #4E5155; }
  .sidenav.bg-white .sidenav-header {
    color: #babbbc; }
  .sidenav.bg-white hr,
  .sidenav.bg-white .sidenav-divider,
  .sidenav.bg-white .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(78, 81, 85, 0.075) !important; }
  .sidenav.bg-white .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-white .sidenav-block::before {
    background-color: rgba(78, 81, 85, 0.075); }
  .sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #f2f2f2; }
  .sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #4E5155; }
  .sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff; }
  .sidenav.bg-white .ps__thumb-y,
  .sidenav.bg-white .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(78, 81, 85, 0.2) !important; }

.sidenav.bg-light {
  background-color: #f1f1f2 !important;
  color: #a3a4a6; }
  .sidenav.bg-light .sidenav-link,
  .sidenav.bg-light .sidenav-horizontal-prev,
  .sidenav.bg-light .sidenav-horizontal-next {
    color: #a3a4a6; }
    .sidenav.bg-light .sidenav-link:hover, .sidenav.bg-light .sidenav-link:focus,
    .sidenav.bg-light .sidenav-horizontal-prev:hover,
    .sidenav.bg-light .sidenav-horizontal-prev:focus,
    .sidenav.bg-light .sidenav-horizontal-next:hover,
    .sidenav.bg-light .sidenav-horizontal-next:focus {
      color: #4E5155; }
    .sidenav.bg-light .sidenav-link.active,
    .sidenav.bg-light .sidenav-horizontal-prev.active,
    .sidenav.bg-light .sidenav-horizontal-next.active {
      color: #4E5155; }
  .sidenav.bg-light .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-light .sidenav-horizontal-prev.disabled,
  .sidenav.bg-light .sidenav-horizontal-next.disabled {
    color: #c2c3c4 !important; }
  .sidenav.bg-light .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-light .sidenav-item.active > .sidenav-link {
    color: #4E5155; }
  .sidenav.bg-light .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #ebebec; }
  .sidenav.bg-light.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #e5e5e6; }
  .sidenav.bg-light.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-light.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #ebebec; }
  .sidenav.bg-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #a3a4a6; }
  .sidenav.bg-light .sidenav-text {
    color: #4E5155; }
  .sidenav.bg-light .sidenav-header {
    color: #b7b7b9; }
  .sidenav.bg-light hr,
  .sidenav.bg-light .sidenav-divider,
  .sidenav.bg-light .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(78, 81, 85, 0.0777227) !important; }
  .sidenav.bg-light .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-light .sidenav-block::before {
    background-color: rgba(78, 81, 85, 0.0777227); }
  .sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #dfdfe0; }
  .sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #4E5155; }
  .sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #ebebec;
            box-shadow: 0 0 0 2px #ebebec; }
  .sidenav.bg-light .ps__thumb-y,
  .sidenav.bg-light .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(78, 81, 85, 0.221782) !important; }

.sidenav.bg-lighter {
  background-color: #f8f8f8 !important;
  color: #a3a4a6; }
  .sidenav.bg-lighter .sidenav-link,
  .sidenav.bg-lighter .sidenav-horizontal-prev,
  .sidenav.bg-lighter .sidenav-horizontal-next {
    color: #a3a4a6; }
    .sidenav.bg-lighter .sidenav-link:hover, .sidenav.bg-lighter .sidenav-link:focus,
    .sidenav.bg-lighter .sidenav-horizontal-prev:hover,
    .sidenav.bg-lighter .sidenav-horizontal-prev:focus,
    .sidenav.bg-lighter .sidenav-horizontal-next:hover,
    .sidenav.bg-lighter .sidenav-horizontal-next:focus {
      color: #4E5155; }
    .sidenav.bg-lighter .sidenav-link.active,
    .sidenav.bg-lighter .sidenav-horizontal-prev.active,
    .sidenav.bg-lighter .sidenav-horizontal-next.active {
      color: #4E5155; }
  .sidenav.bg-lighter .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-lighter .sidenav-horizontal-prev.disabled,
  .sidenav.bg-lighter .sidenav-horizontal-next.disabled {
    color: #c5c6c7 !important; }
  .sidenav.bg-lighter .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-lighter .sidenav-item.active > .sidenav-link {
    color: #4E5155; }
  .sidenav.bg-lighter .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #f2f2f2; }
  .sidenav.bg-lighter.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #ececec; }
  .sidenav.bg-lighter.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-lighter.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #f2f2f2; }
  .sidenav.bg-lighter .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-lighter .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #a3a4a6; }
  .sidenav.bg-lighter .sidenav-text {
    color: #4E5155; }
  .sidenav.bg-lighter .sidenav-header {
    color: #b8b9bb; }
  .sidenav.bg-lighter hr,
  .sidenav.bg-lighter .sidenav-divider,
  .sidenav.bg-lighter .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(78, 81, 85, 0.0763725) !important; }
  .sidenav.bg-lighter .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-lighter .sidenav-block::before {
    background-color: rgba(78, 81, 85, 0.0763725); }
  .sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #e5e6e6; }
  .sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #4E5155; }
  .sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #f2f2f2;
            box-shadow: 0 0 0 2px #f2f2f2; }
  .sidenav.bg-lighter .ps__thumb-y,
  .sidenav.bg-lighter .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(78, 81, 85, 0.21098) !important; }

.footer.bg-secondary {
  background-color: #8897AA !important;
  color: #ebeef1; }
  .footer.bg-secondary .footer-link {
    color: #ebeef1; }
    .footer.bg-secondary .footer-link:hover, .footer.bg-secondary .footer-link:focus {
      color: #fff; }
    .footer.bg-secondary .footer-link.disabled {
      color: #c3cbd5 !important; }
  .footer.bg-secondary .footer-text {
    color: #fff; }
  .footer.bg-secondary .show > .footer-link,
  .footer.bg-secondary .active > .footer-link,
  .footer.bg-secondary .footer-link.show,
  .footer.bg-secondary .footer-link.active {
    color: #fff; }
  .footer.bg-secondary hr {
    border-color: rgba(255, 255, 255, 0.15); }

.footer.bg-success {
  background-color: #02BC77 !important;
  color: #cbf1e3; }
  .footer.bg-success .footer-link {
    color: #cbf1e3; }
    .footer.bg-success .footer-link:hover, .footer.bg-success .footer-link:focus {
      color: #fff; }
    .footer.bg-success .footer-link.disabled {
      color: #7bdcb8 !important; }
  .footer.bg-success .footer-text {
    color: #fff; }
  .footer.bg-success .show > .footer-link,
  .footer.bg-success .active > .footer-link,
  .footer.bg-success .footer-link.show,
  .footer.bg-success .footer-link.active {
    color: #fff; }
  .footer.bg-success hr {
    border-color: rgba(255, 255, 255, 0.15); }

.footer.bg-info {
  background-color: #28c3d7 !important;
  color: #dcf5f8; }
  .footer.bg-info .footer-link {
    color: #dcf5f8; }
    .footer.bg-info .footer-link:hover, .footer.bg-info .footer-link:focus {
      color: #fff; }
    .footer.bg-info .footer-link.disabled {
      color: #94e1eb !important; }
  .footer.bg-info .footer-text {
    color: #fff; }
  .footer.bg-info .show > .footer-link,
  .footer.bg-info .active > .footer-link,
  .footer.bg-info .footer-link.show,
  .footer.bg-info .footer-link.active {
    color: #fff; }
  .footer.bg-info hr {
    border-color: rgba(255, 255, 255, 0.15); }

.footer.bg-warning {
  background-color: #FFD950 !important;
  color: #998230; }
  .footer.bg-warning .footer-link {
    color: #998230; }
    .footer.bg-warning .footer-link:hover, .footer.bg-warning .footer-link:focus {
      color: #665720; }
    .footer.bg-warning .footer-link.disabled {
      color: #c2a53d !important; }
  .footer.bg-warning .footer-text {
    color: #665720; }
  .footer.bg-warning .show > .footer-link,
  .footer.bg-warning .active > .footer-link,
  .footer.bg-warning .footer-link.show,
  .footer.bg-warning .footer-link.active {
    color: #665720; }
  .footer.bg-warning hr {
    border-color: rgba(102, 87, 32, 0.0832855); }

.footer.bg-danger {
  background-color: #d9534f !important;
  color: #f7dbda; }
  .footer.bg-danger .footer-link {
    color: #f7dbda; }
    .footer.bg-danger .footer-link:hover, .footer.bg-danger .footer-link:focus {
      color: #fff; }
    .footer.bg-danger .footer-link.disabled {
      color: #eba5a2 !important; }
  .footer.bg-danger .footer-text {
    color: #fff; }
  .footer.bg-danger .show > .footer-link,
  .footer.bg-danger .active > .footer-link,
  .footer.bg-danger .footer-link.show,
  .footer.bg-danger .footer-link.active {
    color: #fff; }
  .footer.bg-danger hr {
    border-color: rgba(255, 255, 255, 0.15); }

.footer.bg-dark {
  background-color: #2f3337 !important;
  color: #9d9fa1; }
  .footer.bg-dark .footer-link {
    color: #9d9fa1; }
    .footer.bg-dark .footer-link:hover, .footer.bg-dark .footer-link:focus {
      color: #fff; }
    .footer.bg-dark .footer-link.disabled {
      color: #717477 !important; }
  .footer.bg-dark .footer-text {
    color: #fff; }
  .footer.bg-dark .show > .footer-link,
  .footer.bg-dark .active > .footer-link,
  .footer.bg-dark .footer-link.show,
  .footer.bg-dark .footer-link.active {
    color: #fff; }
  .footer.bg-dark hr {
    border-color: rgba(255, 255, 255, 0.06); }

.footer.bg-white {
  background-color: #fff !important;
  color: #a3a4a6; }
  .footer.bg-white .footer-link {
    color: #a3a4a6; }
    .footer.bg-white .footer-link:hover, .footer.bg-white .footer-link:focus {
      color: #4E5155; }
    .footer.bg-white .footer-link.disabled {
      color: #c8c8ca !important; }
  .footer.bg-white .footer-text {
    color: #4E5155; }
  .footer.bg-white .show > .footer-link,
  .footer.bg-white .active > .footer-link,
  .footer.bg-white .footer-link.show,
  .footer.bg-white .footer-link.active {
    color: #4E5155; }
  .footer.bg-white hr {
    border-color: rgba(78, 81, 85, 0.075); }

.footer.bg-light {
  background-color: #f1f1f2 !important;
  color: #a3a4a6; }
  .footer.bg-light .footer-link {
    color: #a3a4a6; }
    .footer.bg-light .footer-link:hover, .footer.bg-light .footer-link:focus {
      color: #4E5155; }
    .footer.bg-light .footer-link.disabled {
      color: #c2c3c4 !important; }
  .footer.bg-light .footer-text {
    color: #4E5155; }
  .footer.bg-light .show > .footer-link,
  .footer.bg-light .active > .footer-link,
  .footer.bg-light .footer-link.show,
  .footer.bg-light .footer-link.active {
    color: #4E5155; }
  .footer.bg-light hr {
    border-color: rgba(78, 81, 85, 0.0777227); }

.footer.bg-lighter {
  background-color: #f8f8f8 !important;
  color: #a3a4a6; }
  .footer.bg-lighter .footer-link {
    color: #a3a4a6; }
    .footer.bg-lighter .footer-link:hover, .footer.bg-lighter .footer-link:focus {
      color: #4E5155; }
    .footer.bg-lighter .footer-link.disabled {
      color: #c5c6c7 !important; }
  .footer.bg-lighter .footer-text {
    color: #4E5155; }
  .footer.bg-lighter .show > .footer-link,
  .footer.bg-lighter .active > .footer-link,
  .footer.bg-lighter .footer-link.show,
  .footer.bg-lighter .footer-link.active {
    color: #4E5155; }
  .footer.bg-lighter hr {
    border-color: rgba(78, 81, 85, 0.0763725); }
